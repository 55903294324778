import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "./userAuth";
import TopNav from "../user/components/topnav";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";

export const ProtectedLayout = () => {
  const { user, refreshUserData } = useAuth();
  const outlet = useOutlet();
 
  useEffect(() => {
    const getUser = async () => {
      const resp = await fetch('https://quantumcrypta.net/api/user/profile', {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({ id: user.owner_id })
      });
      const response = await resp.json();
      refreshUserData(response);
      console.log(response.emailVerificationStatus)
    }
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <Navigate to="/" />;
  }

  // if (!(parseInt(user.emailVerificationStatus))) {
  //   return <Navigate to="/verify/email/" />;
  // }

  // if (!(parseInt(user.idVerifyStatus))) {
  //   return <Navigate to="/verify/identity/" />;
  // }

  return (
    <div className='r-app'>
      <TopNav />
      <main className="app-py-1">
        <div className="fade-appear-done fade-enter-done">
          {outlet}
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};