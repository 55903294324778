import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../authentication/userAuth';
import { ToastContainer, toast } from 'react-toastify';

const CopyTrading = () => {
    const { user } = useAuth();
    const [traders, setTraders] = useState([]);
    const [SearchTraders, setSearchTraders] = useState([]);
    const [up, setUp] = useState(0);
 
    useEffect(() => {
        async function fetchData() {
            const response = await fetch(`https://quantumcrypta.net/api/user/traders/all`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ userid: user.owner_id })
            });
            const resp = await response.json();
            setTraders(resp.traders);
        }
        fetchData();
    }, [up, user.owner_id]);

    const TradeCopyRequest = async (e) => {
        e.preventDefault();
        
        let copy = {
            trader_id: e.target.dataset.trader, 
            copier_id: user.owner_id
        }

        const response = await fetch(`https://quantumcrypta.net/api/user/traders/copy`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(copy)
        });

        const resp = await response.json();
        if (resp.status === 200) {
            // toast.success(resp.message);
            setUp(up+1);
        } else {
            toast.error(resp.message)
        }
    }

    const handleSearch = async (e) => {
        e.preventDefault();

        if (e.target.value.length > 0) {
            let search = traders.filter(trader => trader.name.toLowerCase().includes(e.target.value.toLocaleLowerCase()));
            setSearchTraders(search);
        } else {
            setSearchTraders([]);
        }
    }

    return (
        <div className="container">
            <form>
                <div className="input-field">
                    <input type="search" id="autocomplete-input" className="autocomplete" style={{padding:"0 10px"}} placeholder="Search" onChange={handleSearch} data-target="autocomplete-options-4a2f700f-da8f-0365-0b82-086707faa596" />
                    <ul id="autocomplete-options-4a2f700f-da8f-0365-0b82-086707faa596" className="autocomplete-content">
                        {
                            SearchTraders?.map((trader, index) => (
                                <li key={index} className="collection-item app-mb-2" style={{ "padding": "0px" }}>
                                    <div className="roww app-link-list">
                                        <div className="coll s4 l2 app-trader-border-image">
                                            <center>
                                                <img className="circle" src={`https://quantumcrypta.net/uploads/${trader.image}`} style={{ "maxWidth": "70px", "maxHeight": "70px", marginBottom: "5px" }} alt='' /><br />
                                                {
                                                    (trader.copier.length > 0) ? (
                                                        trader.copier.map((copier, index) => (
                                                            (copier.accepted === "1") ? (
                                                                <button key={index} className="green btnn btnn-full" style={{ "fontSize": "10px", marginBottom: "5px" }}>Accepted</button>
                                                            ) : (

                                                                <button key={index} className="blue btnn btnn-full" style={{ "fontSize": "10px", marginBottom: "5px" }}>Requested</button>
                                                            )
                                                        ))
                                                    ) : (
                                                        <button
                                                            className="green btnn btnn-full"
                                                            style={{ "fontSize": "10px", marginBottom: "5px" }}
                                                            data-trader={trader.id}
                                                            onClick={TradeCopyRequest}>Copy
                                                        </button>
                                                    )
                                                }
                                            </center>
                                        </div>
                                        <Link to={`/user/traders/single/${trader.id}`}>
                                            <div className="coll s8 l4 app-trader-border-name">
                                                <h3>{trader.name}</h3>
                                                <p>{trader.bio}</p>
                                            </div>
                                            <div className="coll s4 l2 center app-trader-border">
                                                <h3>{trader.win_percent}%</h3>Return Rate
                                            </div>
                                            <div className="coll s4 l2 center app-trader-border">
                                                <h3>{trader.followers}</h3>Followers
                                            </div>
                                            <div className="coll s4 l2 center app-trader-border">
                                                <h3>{trader.minimum}</h3>Minimum
                                            </div>
                                        </Link>
                                    </div>
                                </li >
                            ))
                        }
                    </ul>
                </div>
            </form>
            <ToastContainer />
            <ul className="collection roww">
                {
                    traders?.map((trader, index) => (
                        <li key={index} className="collection-item app-mb-2" style={{ "padding": "0px" }}>
                            <div className="roww app-link-list">
                                <div className="coll s4 l2 app-trader-border-image">
                                    <center>
                                        <img className="circle" src={`https://quantumcrypta.net/uploads/${trader.image}`} style={{ "maxWidth": "70px", "maxHeight": "70px", marginBottom: "5px" }} alt='' /><br />
                                        {
                                            (trader.copier.length > 0) ? (
                                                trader.copier.map((copier, index) => (
                                                    (copier.accepted === "1") ? (
                                                        <button key={index} className="green btnn btnn-full" style={{ "fontSize": "10px", marginBottom: "5px" }}>Accepted</button>
                                                    ) : (

                                                        <button key={index} className="blue btnn btnn-full" style={{ "fontSize": "10px", marginBottom: "5px" }}>Requested</button>
                                                    )
                                                ))
                                            ) : (
                                                <button
                                                    className="green btnn btnn-full"
                                                    style={{ "fontSize": "10px", marginBottom: "5px" }}
                                                    data-trader={trader.id}
                                                    onClick={TradeCopyRequest}>Copy
                                                </button>
                                            )
                                        }
                                    </center>
                                </div>
                                <Link to={`/user/traders/single/${trader.id}`}>
                                    <div className="coll s8 l4 app-trader-border-name">
                                        <h3>{trader.name}</h3>
                                        <p>{trader.bio}</p>
                                    </div>
                                    <div className="coll s4 l2 center app-trader-border">
                                        <h3>{trader.win_percent}%</h3>Return Rate
                                    </div>
                                    <div className="coll s4 l2 center app-trader-border">
                                        <h3>{trader.followers}</h3>Followers
                                    </div>
                                    <div className="coll s4 l2 center app-trader-border">
                                        <h3>{trader.minimum}</h3>Minimum
                                    </div>
                                </Link>
                            </div>
                        </li >
                    ))
                }
            </ul>
            <br />
            <center><Link to="/user/traders/copy-trading-agreement">Copy Trading Agreement</Link></center>
        </div >
    )
}

export default CopyTrading
