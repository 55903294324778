import { Link } from 'react-router-dom'

const SideNavLanding = ({ openNav, setopenNav }) => {
    return (
        <>
            <div className={openNav ? "sidenav-overlay open-overlay" : 'sidenav-overlay'} onClick={() => setopenNav(!openNav)}></div>
            <ul className={openNav ? "sidenav openNav" : 'sidenav'} id="sn" onClick={() => setopenNav(!openNav)}>
                <li>
                    <div className="user-view">
                        <div className="background"></div>
                    </div>
                </li>
                <li>
                    <Link to="/" className="sidenav-close">
                        <span className="material-icons notranslate">home</span> Home
                    </Link>
                </li>
                <li><Link to="/login" className="sidenav-close"><span className="material-icons notranslate">person_outline</span> Sign in</Link></li>
                <li><Link to="/register" className="sidenav-close"><span className="material-icons notranslate">person_add</span> Sign up</Link></li>
                <li><Link to="/contact" className="sidenav-close"><span className="material-icons notranslate">mail_outline</span> Contact us</Link></li>


                <li><Link to="/about" className="sidenav-close"><span className="material-icons notranslate">people</span> About us</Link></li>
                <li><Link to="/cookiepolicy" className="sidenav-close"><span className="material-icons notranslate">lock_open</span> Cookie policy</Link></li>
                <li><Link to="/privacy" className="sidenav-close"><span className="material-icons notranslate">lock_open</span> Privacy policy</Link></li>
                <li><Link to="/cryptomining" className="sidenav-close"><span className="material-icons notranslate">dns</span> Crypto mining</Link></li>


                <li><Link to="/bicoinmining" className="sidenav-close"><span className="material-icons notranslate">copyright</span> Bitcoin mining</Link></li>
                <li><Link to="/doge" className="sidenav-close"><span className="material-icons notranslate">copyright</span> Dogecoin mining</Link></li>
                <li><Link to="/copy" className="sidenav-close"><span className="material-icons notranslate">content_copy</span> Copy Trading</Link></li>
                <li><Link to="/crypto" className="sidenav-close"><span className="material-icons notranslate">copyright</span> Crypto Trading</Link></li>



                <li><Link to="/terms" className="sidenav-close"><span className="material-icons notranslate">folder_open</span> Terms of service</Link></li>
                <li><Link to="/forex" className="sidenav-close"><span className="material-icons notranslate">wysiwyg</span> Forex Trading</Link></li>
                <li><Link to="/stocks" className="sidenav-close"><span className="material-icons notranslate">insert_chart_outlined</span> Stocks Trading</Link></li>
            </ul>
        </>
    )
}

export default SideNavLanding
