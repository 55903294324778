
import React, { useEffect, useState } from 'react'

import data from '../../cryptoprices';
import IndexPrices from '../components';
import BottomNav from '../components/bottomnav';
import { useAuth } from '../../authentication/userAuth';



const UserMarkets = () => {
  const { user } = useAuth();
  const [ forexPrices, setForexPrices ] = useState([]);
  const [ stockPrices, setStockPrices ] = useState([]);
  const [ cryptoPricess, setCryptoPrices ] = useState([]);
  const [ etfPrices, setEtfPrices ] = useState([]);
  const [ indexPrices, setIndexPrices ] = useState([]);
 
  const [ watch, setWatch ] = useState([]);
  const [ up, setUp ] = useState(0);

  const [ gen, setGen ] = useState([]);
  const [ mkt, setMkt ] = useState('stock');
  
  useEffect(() => {
    for (const [key, value] of Object.entries(data.data)) {
      if (key === 'forex') {
        setForexPrices(value.data);
      }
      if (key === 'stock') {
        setStockPrices(value.data); 
        setGen(value.data);
        setMkt('stock')
      }
      if (key === 'crypto') {
        setCryptoPrices(value.data);
      }
      if (key === 'etf') {
        setEtfPrices(value.data);
      }
      if (key === 'index') {
        setIndexPrices(value.data);
      }
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
        const resp = await fetch('https://quantumcrypta.net/api/user/trading/wishlist/all/id', {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({ id: user.owner_id })
        });
        const repsonse = await resp.json();
        
        setWatch(repsonse.wishlist);
        console.log(watch)
    }
    fetchData();
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [up, user.owner_id])

  const relo = (param) => {
    setUp(up +1);
    if(param === 'stock'){
      setGen(stockPrices);
      setMkt('stock');
    }
    if(param === 'crypto'){
      setGen(cryptoPricess)
      setMkt('crypto')
    }
    if(param === 'forex'){
      setGen(forexPrices)
      setMkt('forex')
    }
    if(param === 'etf'){
      setGen(etfPrices)
      setMkt('etf')
    }
    if(param === 'index'){
      setGen(indexPrices)
      setMkt('index')
    }
  }
  
  return (
    <>
      <div className="container">
        <div className="roww center" style={{ "fontSize": "14px", "textAlign": "center !important" }}>
          <div className="coll l2 offset-l1 offset-s1 s2" onClick={()=> relo('stock')}>
            <img src="https://quantumcrypta.net/assets/images/svgs/IconStocks.svg" style={{ "width": "18px", "height": "20px" }} alt="" /><br />
            <span style={{ "color": "rgb(133, 137, 155)" }}>Stocks</span>
          </div>
          <div className="coll l2 s2" onClick={()=> relo('crypto')}>
            <img src="https://quantumcrypta.net/assets/images/svgs/IconCrypto.svg" style={{ "width": "18px", "height": "20px" }} alt="" /><br />
            <span style={{ "color": "rgb(133, 137, 155)" }}>Crypto</span>
          </div>
          <div className="coll l2 s2" onClick={()=> relo('forex')}>
            <img src="https://quantumcrypta.net/assets/images/svgs/IconCurrencies.svg" style={{ "width": "18px", "height": "20px" }} alt="" /><br />
            <span style={{ "color": "rgb(133, 137, 155)" }}>Forex</span>
          </div>
          <div className="coll l2 s2" onClick={()=> relo('etf')}>
            <img src="https://quantumcrypta.net/assets/images/svgs/IconIndices.svg" style={{ "width": "18px", "height": "20px" }} alt="" /><br />
            <span style={{ "color": "rgb(133, 137, 155)" }}>ETFs</span>
          </div>
          <div className="coll l2 s2" onClick={()=> relo('index')}>
            <img src="https://quantumcrypta.net/assets/images/svgs/IconIndices.svg" style={{ "width": "18px", "height": "20px" }} alt="" /><br />
            <span style={{ "color": "rgb(133, 137, 155)" }}>Index</span>
          </div>
        </div><br />
        <ul className="collection roww">
          {
            gen && gen?.map((data)=>(
              <IndexPrices 
                data={data} 
                addToWish={true} 
                currentUser={user.owner_id} 
                mrkt={mkt} 
                relo={relo} 
                fr={false} 
                myWatch={watch} 
                setUp={setUp}
              />
            ))
          }
        </ul>
      </div>
      <BottomNav markets='active' />
    </>
  )
}

export default UserMarkets
