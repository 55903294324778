import React from 'react'
import LayoutLanding from './layout'
import { Link } from 'react-router-dom'

const CryptoTrading = () => {
    return (
        <LayoutLanding>
            {/* <!--banner start--> */}
            <div class="hero-banner creative-banner blog-banner text-center" id="home" style={{height:"300px"}}>
                <div class="hero-text ">
                    <div class="container">
                        <div class="row ">
                            <div class="col-md-12">
                                <h1 class="hero-title text-light mb-0" >Crypto Trading</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--banner end-->

<!--section start--> */}

            <section class="section-gap">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 pr-lg-5 pb-5">
                            <article class="blog-post">
                                <div class="d-flex mb-3 post-meta">
                                    <span class="post-date pr-3">Author: <Link to="#">Admin</Link></span>
                                    <span class="post-date border border-top-0 border-bottom-0 px-3">June 10, 2018</span>
                                    <Link to="#">
                                        <span class="d-flex align-items-center px-3"><i class="fa fa-comments-o text-3 mr-1" aria-label="4 users comment this post"></i> 4</span>
                                    </Link>
                                </div>
                                <p>
                                    Quantumcrypta is excited to announce the launch of our new cryptocurrency trading platform. Now you can start trading Bitcoin, Ethereum and many more cryptocurrencies quickly, easily and safely from wherever you are — in just seconds. You get great margin trading leverage and short sell options with fast deposits and withdrawals. Our support team is available 24/7/365 to help get you trading on our CySEC-regulated platform with a trading volume of US $11 billion monthly.
                                </p>

                                <p>
                                    What is a crypto currency<br />
                                    A cryptocurrency like bitcoin is a virtual currency traded peer-to-peer on a blockchain, independent of centralized authorities like banks and governments. Cryptocurrencies are entirely virtual, so they are not backed by physical commodities and have no intrinsic value.
                                </p>

                                <p>
                                    How Do Cryptocurrencies Work?<br />
                                    Primarily, cryptocurrencies rely on blockchain technology to complete a transaction via an intricate P2P network. Once a transfer request is entered into the network, it is validated by the network and added to a pool of other transactions to create a block of data for the ledger, which is then entered into the existing blockchain. Once the block is successfully added to the chain, the transaction is approved and completed.

                                </p>

                                <p>
                                    Are There Investment Opportunities with Cryptocurrencies?<br />
                                    Absolutely. Cryptocurrencies have become established investment commodities among major financial institutions and have even been adopted by countries such as Australia and Japan. As with any investment though, there are risks linked to market movements, high volatility and economics.
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </LayoutLanding>
    )
}

export default CryptoTrading
