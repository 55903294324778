import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

const NotificationSingle = () => {
    const [notification, setDeposit] = useState({})
    const { id } = useParams();
    useEffect(() => {
        const fetchData = async () => {
            const data = await fetch(`https://quantumcrypta.net/api/user/notifications/single`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                }, 
                body: JSON.stringify({ id })
            })
            const response = await data.json();
            if (response.status === 200) {
                setDeposit(response.notifications)
            }
        }

        setTimeout(async () => {
            await fetch(`https://quantumcrypta.net/api/user/notification/viewed/`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ id })
            })
        }, 2000);
        fetchData();
    }, [id]);
    return (
        <>
            <section className="container">
                <div className="roww">
                    <div className="coll l4 s12 ">
                        <h3 className="app-trading-day">{notification.subject}</h3>
                    </div>
                    <div className="coll l8 s12"><br />
                        {notification.body}
                        <div className='my-2 fw-bold'>
                            <div className="app-trading-day">{notification.createdAt}</div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default NotificationSingle
