import React, { useEffect, useState } from 'react'
import IndexPrices from '../components';
import { useAuth } from '../../authentication/userAuth';

const UserWatchList = () => {
    const { user } = useAuth();
    const [gen, setGen] = useState([]);
    const [ up, setUp ] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const resp = await fetch('https://quantumcrypta.net/api/user/trading/wishlist/all', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ id: user.owner_id })
            });
            const repsonse = await resp.json();
            setGen(repsonse.wishlist);
        }
        fetchData();
    }, [up, user.owner_id])

    const relo = (v) => {
        setUp(up +1);
    }
    return (
        <>
            <div>
                <ul className="collection">
                    {
                        (gen?.length > 0) ? (
                            gen.map((data) => (
                                <IndexPrices data={data} addToWish={false} fr={true} currentUser={user.id} relo={relo}/>
                            ))

                        ) : (
                                <li className="collection-item app-py-2">
                                    <p id="no-data" style={{ "textAlign": "center" }}>
                                        <span className="app-font-normal">No Watch list yet</span>
                                    </p>
                                </li>
                        )
                    }
                </ul>
                <br /><br />
            </div> 
        </>
    )
}

export default UserWatchList
