import React, { useContext, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from '../../context';
import Qrcode from './qrcode';

const DepositStep4 = () => {
    const { depositDetails } = useContext(AuthContext);
    const { id } = useParams();
    const [copyText, setCopyText] = useState(depositDetails.wallet_address);

    const handleCopyText = (e) => {
        setCopyText(e.target.value);  
    };

    return (  
        <>
            <section className="roww center">
                <div className="coll l4 offset-l4 s12">
                    <div className="cardd-panel">
                        <p>SEND {Math.fround(depositDetails.amount/6500)} BTC</p>
                        <p>TO THE WALLET ADDRESS BELOW OR SCAN THE QR CODE WITH YOUR <strong style={{fontWeight:"bold"}}>{depositDetails.paytype.toUpperCase()}</strong> WALLET APP</p> 
                        <p></p>
                        <div className="input-field">
                            <CopyToClipboard text={copyText}
                                onCopy={() => toast(depositDetails.wallet_address)}>
                                <i className="material-icons prefix notranslate">content_copy</i>
                            </CopyToClipboard>
                            <input
                                type="text"
                                value={copyText}
                                onChange={handleCopyText}
                                style={{ "textAlign": "center" }}
                            />
                        </div>
                        <div className="background">
                            <Qrcode wallet={depositDetails.wallet_address} />
                        </div>
                        <br />
                        <p>Awaiting Payment</p>
                        <Link className="btnn" to={`/user/deposits/upload-proof/${id}`}>UPLOAD PAYMENT PROOF</Link>
                        <br />
                        <Link className="btnn btnn-secondary" to="/user/deposits/list">WAIT FOR CONFIRMATION</Link>
                    </div>
                    <ToastContainer />
                </div>
            </section>
        </>
    )
}

export default DepositStep4
