import React, { useEffect, useState } from 'react'
import { useAuth } from '../authentication/userAuth';
import { Link } from 'react-router-dom';

const NotificationList = () => {
    const { user } = useAuth();
    const [deposits, setDeposits] = useState([]);
    const month = { '01': 'Jan', '02': 'Feb', '03': 'Mar', '04': 'Apr', '05': 'May', '06': 'June', '07': 'July', '08': 'Aug', '09': 'Sep', '10': 'Oct', '11': 'Nov', '12': 'Dec' };

    useEffect(() => { 
        const fetchData = async () => {
            const data = await fetch(`https://quantumcrypta.net/api/user/notifications/all`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    userid: user.owner_id
                })
            })
            const response = await data.json();
            setDeposits(response.notifications);
        }
        fetchData();
    }, [user.owner_id])
    return (
        <div className="container">
            <ul className="collection app-mx-1">
                <li className="collection-item">
                    All notifications
                    <span style={{ "float": "right", "cursor": "pointer" }}> X</span>
                </li>
            </ul>
            <ul className="collection roww">
                {
                    (deposits.length > 0) ? (
                        deposits.map((item, index) => (
                            <li className="collection-item app-py-1 " key={index}>
                                <div className="roww app-list-link">
                                    <Link to={`/user/notifications-list/single/${item.id}`}>
                                        <div className="coll l1 s2" style={{ "padding": "0px" }}>
                                            <center>
                                                <p className="app-trading-month">{month[item.createdAt.split(' ')[0].split('-')[1]]}</p>
                                                <span className="app-trading-day">
                                                    {item.createdAt.split(' ')[0].split('-')[2].split('')[0]}{item.createdAt.split(' ')[0].split('-')[2].split('')[1]}
                                                </span>
                                            </center>
                                        </div>
                                        <div className="coll l9 s7" style={{ "fontSize": "14px" }} >
                                            {item.subject}
                                        </div>
                                    </Link>
                                    <div className="coll l2 s3" style={{ "textAlign": "right", "fontSize": "14px" }}>{
                                        item.seen ? 'Read' : 'Unread'
                                    }</div>
                                </div>
                            </li >
                        ))
                    ) : (
                        <li class="collection-item app-py-2">
                            <p id="no-data" style={{ "text-Align": "center" }}><span class="app-font-normal">NO NOTIFICATIONS YET</span></p>
                        </li>
                    )
                }
            </ul >
        </div >
    )
}

export default NotificationList
