import React from 'react'
import { Link } from 'react-router-dom'
import LayoutLanding from './layout'

const CookiePolicy = () => {
    return (
        <LayoutLanding>
            <div>
            {/* <!--banner start--> */}
            <div class="hero-banner creative-banner blog-banner text-center" id="home" style={{height:"300px"}}>
                <div class="hero-text">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <h1 class="hero-title text-light mb-0" >Cookie Policy</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--banner end--> */}

            {/* <!--section start--> */}

            <section class="section-gap">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 pr-lg-5 pb-5">
                            <article class="blog-post">
                                {
                                /* <!--<header class="blog-post-header mb-4">-->
                                <!--    <img src="assets/img/b1.jpg" class="" alt="">-->
                                <!--</header>--> 
                                */}

                                <div class="d-flex mb-3 post-meta">
                                    <span class="post-date pr-3">Author: <Link to="#">Admin</Link ></span>
                                    <span class="post-date border border-top-0 border-bottom-0 px-3">June 10, 2018</span>
                                    <Link to="#">
                                        <span class="d-flex align-items-center px-3">
                                            <i class="fa fa-comments-o text-3 mr-1" aria-label="4 users comment this post"></i> 4
                                        </span>
                                    </Link >
                                </div>
                                <p>
                                    Quantumcrypta uses cookies to: Optimize your trading experience, including remembering your preferences, location, preferred language, browser and other details;
                                </p>

                                <p>
                                    Authenticate your identity for security purposes;<br />

                                    Maintain our website and certain functions available on it;<br />

                                    Analyze and track the use of our services;<br />

                                    Adjust our platform according to your trading habits and our regulatory requirements.
                                </p>


                                <h3>WHAT ARE COOKIES</h3><br />
                                <p>
                                    Cookies are tiny pieces of data that are sent to your computer by a website and stored on your computer. Cookies are non-executable and cannot be used to install malware. They allow websites to recognize visitors when they return and include basic information about them that is checked and updated every time you visit the website. For more information see: http://www.allaboutcookies.org/.
                                </p>


                                <h3>MANAGING COOKIES</h3><br />
                                <p>
                                    At Quantumcrypta, we respect your right to privacy and are therefore happy to provide you with tools to manage the cookies you receive from our web services. Some cookies are essential to the performance of our platform (you cannot opt out from these cookies if you wish to use our platform). Below you will find a list of some of the third party cookies used by Quantumcrypta. For those users who would prefer not to receive non-essential cookies, we have provided an “opt out” option for you to select.<br /><br />

                                    Your browser may also allow you to block the storage of cookies on your computer; please refer to your browser’s “Help” menu or website for more information. If you use our web services without blocking cookies you are actually consenting to the cookies.
                                </p>

                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        </LayoutLanding>
    )
}

export default CookiePolicy
