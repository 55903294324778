import { AuthProvider } from './authentication/userAuth';
import { Route, Routes } from 'react-router-dom';
import { AuthContext, ThemeContext } from './context';
import { useState } from 'react';

import HomeLanding from './landing/home';
import Login from './landing/login';
import ContactUs from './landing/contact';
import NopageLanding from './landing/noPage';
import ForgotPasswordHome from './landing/forgotpassword';
import ForexTrading from './landing/forex';
import StockTradingLanding from './landing/stockstrading';
import RegisterationHome from './landing/register';
import EmailVerificationLanding from './landing/email';
import IdentityVerificationLanding from './landing/identity';
import Verify from './landing/component/verify';
import CookiePolicy from './landing/cookiepolicy';
import PrivacyPolicy from './landing/privacy';
import DogeCoinMining from './landing/doge';
import BitcoinMining from './landing/bitcoin';
import CryptoTrading from './landing/cryptoTrading';
import CryptoMining from './landing/cryptomining';
import TermsLanding from './landing/terms';
import AboutLanding from './landing/about';
import CopyTradingLanding from './landing/copy';
import Faq from './landing/faq';

import { ProtectedLayout } from './authentication/protectedLayout';
import HomeDashboard from './user/home';
import MyPosts from './user/posts';
import BuyCrypto from './user/pages/buycrypto';
import UserReferals from './user/referals';
import UserTrades from './user/trading/trades';
import MiningPage from './user/mining';
import NotificationList from './user/notificationList';
import NotificationSingle from './user/notificationSingle';
import UserWatchList from './user/trading/watchlist';
import UserMarkets from './user/trading/markets';
import TraderRoom from './user/trading/traderroom';
import VerificationHome from './user/verification';
import AddressVerification from './user/verification/address';
import IdentityVerification from './user/verification/identity';
import AddressUpdate from './user/accountupdates/address';
import EmailUpdate from './user/accountupdates/email';
import PhotoUpdate from './user/accountupdates/photo';
import PasswordUpdate from './user/accountupdates/password';
import UserAccount from './user/account';
import CopyTradingSingle from './user/trading/copyTradingsingle';
import CopyTradingAgreement from './user/trading/copyTradingAgreement';
import MiningContracts from './user/miningcontracts';
import AllPlans from './user/plans/allplans';
import MiningPlans from './user/plans/mining';
import TradingPlans from './user/plans/trading';
import SignalPlans from './user/plans/signals';
import CryptoWithdrawal from './user/withdraws/crypto';
import WithdrawalList from './user/withdraws/list';
import WithdrawSingle from './user/withdraws/withdrawsingle';
import DepositFunds from './user/deposit/fund';
import DepositStep1 from './user/deposit/step1';
import DepositStep2 from './user/deposit/step2';
import DepositStep4 from './user/deposit/step4';
import DepositStep3 from './user/deposit/step3';
import DepositSingle from './user/deposit/single';
import DepositList from './user/deposit/list';
import UploadProof from './user/deposit/uploadproof';
import SingleContract from './user/singlecontract';
import CopyTrading from './user/trading/copyTrading';

function App() {
  const [theme, setTheme] = useState('light');
  const [currentUser, setCurrentUser] = useState(null);
  const [depositDetails, setDepositDetails] = useState({});
  const [sitesettings, setSitesettings] = useState({}); 

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <AuthContext.Provider value={{ currentUser, setCurrentUser, depositDetails, setDepositDetails,sitesettings, setSitesettings }}>
        <AuthProvider>
          <Routes>
            <Route path="/" index element={<HomeLanding />} />
            <Route path="/about/" element={<AboutLanding />} />
            <Route path="/contact/" element={<ContactUs />} />
            <Route path="/faq/" element={<Faq />} />
            <Route path="/login/" element={<Login />} />
            <Route path="/register/" element={<RegisterationHome />} />
            <Route path="*" element={<NopageLanding />} />
            <Route path='/forgot-password/' element={<ForgotPasswordHome />} />
            <Route path="/forextrading/" element={<ForexTrading />} />
            <Route path="/stocks/" element={<StockTradingLanding />} />
            <Route path="/terms/" element={<TermsLanding />} />
            <Route path="/cookiepolicy/" element={<CookiePolicy />} />
            <Route path="/privacy/" element={<PrivacyPolicy />} />
            <Route path="/forex/" element={<ForexTrading />} />
            <Route path="/crypto/" element={<CryptoTrading />} />
            <Route path="/doge/" element={<DogeCoinMining />} />
            <Route path="/bicoinmining/" element={<BitcoinMining />} />
            <Route path="/copy/" element={<CopyTradingLanding />} />
            <Route path="/cryptomining/" element={<CryptoMining />} />

            <Route path='/verify' element={<Verify />} >
              <Route path="/verify/email/" element={<EmailVerificationLanding />} />
              <Route path="/verify/identity/" element={<IdentityVerificationLanding />} />
            </Route>

            <Route path="/user" element={<ProtectedLayout />}>
              <Route index path="/user"  element={<HomeDashboard />} />
              <Route path='/user/referals' element={<UserReferals />} />
              <Route path='/user/mining' element={<MiningPage />} />
              <Route path='/user/trades' element={<UserTrades/>} />
               <Route path='/user/notifications-list' element={<NotificationList />} />
              <Route path='/user/notifications-list/single/:id' element={<NotificationSingle />} />

              <Route path='/user/buy-crypto' element={<BuyCrypto />} />
              <Route path='/user/posts' element={<MyPosts />} />

              <Route path='/user/account'>
                <Route index path='/user/account' element={<UserAccount />} />
                <Route path='/user/account/verification/home' element={<VerificationHome />} />
                <Route path='/user/account/verification/address' element={<AddressVerification />} />
                <Route path='/user/account/verification/identity' element={<IdentityVerification />} />
              
                <Route path='/user/account/update/address' element={<AddressUpdate />} />
                <Route path='/user/account/update/password' element={<PasswordUpdate />} />
                <Route path='/user/account/update/photo' element={<PhotoUpdate />} />
                <Route path='/user/account/update/email' element={<EmailUpdate />} />
              </Route>

              <Route path='/user/trading'>
                <Route path='/user/trading/watchlist' element={<div className='container'><UserWatchList /></div>} />
                <Route path='/user/trading/markets' element={<UserMarkets />} />
                <Route path='/user/trading/room/:market/:name' element={<TraderRoom />} />
              </Route>

              <Route path='/user/traders/'>
                <Route index path='/user/traders/' element={<CopyTrading />} />
                <Route path='/user/traders/single/:id' element={<CopyTradingSingle />} />
                <Route path='/user/traders/copy-trading-agreement' element={<CopyTradingAgreement />} />
              </Route>

              <Route path='/user/contracts/'>
                <Route index path='/user/contracts/' element={<MiningContracts />} />
                <Route path='/user/contracts/mining-contracts' element={<MiningContracts />} />
                <Route path='/user/contracts/single' element={<SingleContract />} />
              </Route>

              <Route path='/user/deposits/'>
                <Route index path='/user/deposits/fund' element={<DepositFunds />} />
                <Route path='/user/deposits/fund/step1' element={<DepositStep1 />} />
                <Route path='/user/deposits/fund/step2/:amount/:account' element={<DepositStep2 />} />
                <Route path='/user/deposits/fund/step3/:type/:amount/:account' element={<DepositStep3 />} />
                <Route path='/user/deposits/fund/step4/:wallet_address/:id' element={<DepositStep4 />} />
                <Route path='/user/deposits/single/:id' element={<DepositSingle />} />
                <Route path='/user/deposits/list' element={<DepositList />} />
                <Route path='/user/deposits/upload-proof/:id' element={<UploadProof />} />
              </Route>

              <Route path='/user/withdrawals'>
                <Route index path='/user/withdrawals/crypto' element={<CryptoWithdrawal />} />
                <Route path='/user/withdrawals/list' element={<WithdrawalList />} />
                <Route path='/user/withdrawals/crypto/single/:id' element={<WithdrawSingle />} />
              </Route>

              <Route path='/user/plans/'>
                <Route index path='/user/plans/' element={<AllPlans />} />
                <Route path='/user/plans/categories/mining' element={<MiningPlans />} />
                <Route path='/user/plans/categories/trading' element={<TradingPlans />} />
                <Route path='/user/plans/categories/signals' element={<SignalPlans />} />
              </Route>
            </Route> 

          </Routes>
        </AuthProvider>
      </AuthContext.Provider>
    </ThemeContext.Provider>
  );
}

export default App;

