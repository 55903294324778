import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import SideNavLanding from './component/sidenav'
import GoogleTranslate from './component/googleTranslate';

const NavigationLanding = () => {
    const [openNav, setopenNav] = useState(false);
    return (
        // <!-- header begin -->
        <>
            {/* <!--header start--> */}
            <header className="app-header navbar-purple">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="navbar navbar-expand-md" id="mainNav">
                                {/* <!--logo--> */}
                                <Link className="navbar-brand mr-5" to="/">
                                    {/* <!-- <img className="" src="assets/img/logo2.png" srcset="assets/img/logo2@2x.png 2x" alt=""/> --> */}
                                    Quantumcrypta
                                </Link>
                                {/* <!--logo--> */}

                                <span style={{ "fontSize": "30px", "cursor": "pointer" }} className='nav-span' onClick={() => setopenNav(!openNav)}>&#9776;</span>

                                <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                                    <ul className="navbar-nav ml-auto">
                                        <li className="nav-item">
                                            <Link className="nav-link js-scroll-trigger" to="/">HOME</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link js-scroll-trigger" to="/about">ABOUT US</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link js-scroll-trigger" to="/faq">FAQ</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link js-scroll-trigger" to="#">
                                                <GoogleTranslate />
                                            </Link>
                                        </li>
                                        {/* <li className="nav-item">
                                            <Link className="nav-link js-scroll-trigger" to="#">
                                                <GoogleTranslate />
                                            </Link>
                                        </li> */}
                                    </ul>
                                </div>
                                {/* <!--nav link--> */}
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            <SideNavLanding openNav={openNav} setopenNav={setopenNav} />
        </>
    )
}

export default NavigationLanding
