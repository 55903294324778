import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../context';

const FooterLanding = () => {
  const { sitesettings } = useContext(AuthContext);
  return (
    <>

      {/* <!--footer start--> */}
      <footer className="app-footer">
        <div className="primary-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-3 wow fadeInUp" data-wow-delay=".2s">
                <h4 className="text-uppercase">Quantumcrypta</h4>
                <p></p>
                <ul className="list-unstyled">
                  <li> <Link to={`mailto:${sitesettings.email}`}>{sitesettings.email}</Link></li>
                  <li><Link to={`tel:${sitesettings.phone}`}>{sitesettings.phone}</Link></li>
                </ul>
                <p className="copyright">&copy; Copyright 2018 Inc.™</p>
              </div>
              <div className="col-md-3 mb-md-0 mb-3 wow fadeInUp" data-wow-delay=".3s">
                <h6 className="text-uppercase">Explore</h6>
                <ul className="list-unstyled">
                  <li><Link to="/faq">FAQ</Link></li>
                  <li><Link to="/about">ABOUT</Link></li>
                  <li><Link to="/contact">CONTACT</Link></li>
                </ul>
              </div>
              <div className="col-md-3 mb-md-0 mb-3 wow fadeInUp" data-wow-delay=".3s">
                <h6 className="text-uppercase">SERVICES</h6>
                <ul className="list-unstyled">
                  <li><Link to="/copy">COPY TRADING</Link></li>
                  <li><Link to="/forex">FOREX TRADING</Link></li>
                  <li><Link to="/crypto">CRYPTO TRADING</Link></li>
                </ul>
              </div>
              <div className="col-md-3 wow fadeInUp" data-wow-delay=".4s">
                <h6 className="text-uppercase">RESOURCES</h6>
                <ul className="list-unstyled">
                  <li><Link to="/cookiepolicy">COOKIE POLICY</Link></li>
                  <li><Link to="/privacy">PRIVACY POLICY</Link></li>
                  <li><Link to="/terms">TERMS OF SERVICE</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="secondary-footer text-md-left text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-md-0 mb-3">
                <div className="payment-cards wow fadeInUp" data-wow-delay=".45s">
                  <img src="assets/img/fc1.png" alt="" />
                  <img src="assets/img/fc2.png" alt="" />
                  <img src="assets/img/fc3.png" alt="" />
                  <img src="assets/img/fc4.png" alt="" />
                </div>
              </div>
              <div className="col-md-6 text-md-right wow fadeInUp" data-wow-delay=".5s">
                <div className="social-links float-md-right">
                  <Link to="#"><i className="fa fa-facebook"></i></Link>
                  <Link to="#"><i className="fa fa-twitter"></i></Link>
                  <Link to="#"><i className="fa fa-youtube"></i></Link>
                  <Link to="#"><i className="fa fa-linkedin"></i></Link>
                  <Link to="#"><i className="fa fa-google-plus"></i></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!--footer end--> */}

      <div className="d-none d-md-block">
        <Link to="#" className="to-top fas fa-arrow-up text-decoration-none text-white"></Link>
      </div>

      <Link to={`https://wa.me/${sitesettings.phone}?text=Hello`} className="floating" target="_blank">
        <i className="fab fa-whatsapp fab-icon"></i>
      </Link>
    </>
  )
}

export default FooterLanding
