import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom';
import Fft from './fft';

const IndexPrices = ({ data, addToWish, currentUser, mrkt, relo, fr, myWatch }) => {
    const navigate = useNavigate();

    const Wishlist = async () => {
        if (addToWish) {
            let mywish = {
                icon: data.icon,
                name: data.name,
                tradingview: data.tradingview,
                price_usd: data.price_usd,
                userid: currentUser,
                market_id: data.id
            }

            const dd = await fetch('https://quantumcrypta.net/api/user/trading/wishlist/add', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(mywish)
            })

            const resp = await dd.json();
            if (resp.status === 200) {
                relo(mrkt);
            }
        }
        return
    }

    const RemoveWishlist = async () => {
        let mywish;
        if(addToWish) {
            mywish = {
                userid: currentUser,
                market_id: data.id
            }
        }else{
            mywish = {
                userid: currentUser,
                market_id: data.market_id
            }
        }

        const dd = await fetch('https://quantumcrypta.net/api/user/trading/wishlist/remove', {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(mywish)
        })

        const resp = await dd.json();
        if (resp.status === 200) {
            relo(mrkt);
        } 
        return
    }

    const nav = () => {
        if (addToWish) {
            navigate(generatePath('/user/trading/room/:market/:name', {
                name: data.tradingview,
                market: mrkt
            }));
        }
    }

    return (
        <>
            <li className="collection-item app-py-2">
                <div className="roww">
                    <div className='coll s9 l9' onClick={nav}>
                        <div className="coll s2 l1">
                            <img src={data.icon} style={{ "width": "30px", "height": "30px", marginRight:"5px" }} alt={data.name} />
                        </div>
                        <div className="coll s7 l9">{data.tradingview}<br />{data.name}</div>
                        <div className="coll s2 l1">{' '}</div>
                    </div>

                    {
                        fr ? (
                            <div className="coll l1" style={{ "paddingTop": "10px", textAlign: 'right' }} onClick={RemoveWishlist}>
                                <span className="material-icons notranslate" style={{ color: "#6648fe" }}>star</span>
                            </div>
                        ) : (
                            <Fft myWatch={myWatch} id={data.id} Wishlist={Wishlist} removeWishlist={RemoveWishlist}/>
                        )
                    }
                </div>
            </li>
        </>
    )
}

export default IndexPrices
