import React, { useEffect, useState } from 'react'
import { useAuth } from '../../authentication/userAuth';
import { InfinitySpin } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CryptoWithdrawal = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [isLoading, setIsloading] = useState(false);
  const [balance, setBalance] = useState("trading_deposit");
  const [currency, setCurrency] = useState("BTC");
  const [wallet, setWallet] = useState('');
  const [amount, setAmount] = useState(0);
  const [error, setError] = useState('');
  const [wallets, setWallets] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const dd = await fetch('https://quantumcrypta.net/api/user/wallets/', { method: "POST" });
      const resp = await dd.json();
      setWallets(resp);
    }
    fetchData();
  }, []);

  const handleWithdraw = async (e) => {
    e.preventDefault();

    setIsloading(true);

    let data = {
      to_wallet: wallet,
      withdrawer_id: user.owner_id,
      from_wallet: "System wallet",
      currency:currency,
      amount:amount,
      withdraw_from: balance
    }

    const dd = await fetch('https://quantumcrypta.net/api/user/withdraw/add', {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify(data)
    });

    const resp = await dd.json();
    if (resp.status === 200) {
      setIsloading(false);
      toast.success(resp.message);
      setTimeout(() => {
        navigate('/user/withdrawals/list');
    }, 2000);
    } else {
      setIsloading(false);
      toast.error(resp.message) 
      setError(resp.message)
    }
  }
  return (
    <>
      <section className="roww center">
        <div className="coll l4 s12 offset-l4">
          <p>CRYPTO WITHDRAWAL</p>
          <div className="cardd-panel">
            <form autocomplete="off" onSubmit={handleWithdraw}>
              <div className="roww">
                <div className="input-field undefined">
                  <label className="active">From</label>
                  <select id="from" className="browser-default app-mb-3" onChange={(e) => setBalance(e.target.value)}>
                    <option value="trading_deposit" selected="">Trading Deposit Balance (${user.trading_deposit})</option>
                    <option value="trading_profit" >Trading Profit Balance (${user.trading_profit})</option>
                    <option value="btc_mining">Bitcoin Mining ({user.btc_mining} BTC) </option>
                    <option value="eth_mining">Ethereum Mining ({user.eth_mining} ETH)</option>
                    <option value="cosmos_mining">Cosmos Mining ({user.cosmos_mining} ATOM)</option>
                    <option value="dogecoin">Dogecoin Mining ({user.dogecoin} DOGE)</option>
                    <option value="binince_coin">Binance Coin Mining ({user.binince_coin} BNB)</option>
                    <option value="referal_balance">Referral Balance (${user.referal_balance})</option>
                  </select>
                </div>
                <div className="input-field undefined">
                  <label className="active">crypto currency</label>
                  <select id="crypto_currency" className="browser-default undefined" onChange={(e) => setCurrency(e.target.value)}>
                    <option value="ddd" selected>Select wallet</option>
                      {
                        wallets.map((wallet) => (
                          <option value={wallet.short_name}>{wallet.coin_name}</option>
                        ))
                      }
                  </select>
                </div>
                <div>
                  <div className="input-field">
                    <span className=" prefix">USD</span>
                    <label className="active" for="amount">amount</label>
                    <input type="number" id="amount" min="" max="" step="any" inputmode="decimal" name="amount" className="" required="" value={amount} onChange={(e) => setAmount(e.target.value)} />
                  </div>
                </div>
                <div>
                  <div className="input-field undefined">
                    <input type="text" id="wallet_address" name="wallet_address" maxlength="300" inputmode="text" required="" value={wallet} onChange={(e) => setWallet(e.target.value)} />
                    <label className="active" for="wallet_address">wallet address</label>
                  </div>
                </div>
              </div>

              {
                isLoading ? (
                  <InfinitySpin
                    visible={true}
                    width="200"
                    color="#4fa94d"
                    ariaLabel="infinity-spin-loading"
                  />
                ) : (
                  <>
                    <div><button type="submit" className="btnn btnn-full">Submit</button></div>
                    <br />
                    {
                      error && (
                        <div className="red black-text lighten-4 cardd-panel">
                          <span>{error}</span><br />
                        </div>
                      )
                    }
                  </>
                )
              }
              <ToastContainer />
            </form>
          </div>
        </div>
      </section>
    </>
  )
}
export default CryptoWithdrawal