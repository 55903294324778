import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../authentication/userAuth';

const WithdrawalList = () => {
  const { user } = useAuth();
  const month = { '01': 'Jan', '02': 'Feb', '03': 'Mar', '04': 'Apr', '05': 'May', '06': 'June', '07': 'July', '08': 'Aug', '09': 'Sep', '10': 'Oct', '11': 'Nov', '12': 'Dec' };
  
  const [gen, setGen] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const resp = await fetch('https://quantumcrypta.net/api/user/withdraw/all/', {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({ id: user.owner_id })
      });
      const response = await resp.json();
      setGen(response.withdraws);
    }
    fetchData();
  }, [user.owner_id])
  return ( 
    <>
      <div className="container app-py-1">
        <center><Link to="/user/withdrawals/crypto" className="btnn btnn-large">+ NEW WITHDRAWAL</Link><br /></center><br/>
        <ul className="collection">
          { 
            (gen?.length > 0)? (
              gen?.map((item, index) => (
                <li className="collection-item app-py-1 " key={index}>
                  <div className="roww app-list-link">
                    <Link to={`/user/withdrawals/crypto/single/${item.id}`}>
                      <div className="coll l1 s2" style={{ "padding": "0px" }}>
                      <center>
                            <p className="app-trading-month">{month[item.createdAt.split(' ')[0].split('-')[1]]}</p>
                            <span className="app-trading-day">
                              {item.createdAt.split(' ')[0].split('-')[2].split('')[0]}{item.createdAt.split(' ')[0].split('-')[2].split('')[1]}
                            </span>
                          </center>
                      </div>
                      <div className="coll l9 s7" style={{ "fontSize": "14px" }} >
                        WITHDREW FUNDS OF ${item.amount}<br />
                      </div>
                    </Link>
                    <div className="coll l2 s3" style={{ "textAlign": "right", "fontSize": "14px" }}>{item.status}</div>
                  </div>
                </li >
              ))
            ) : (
              <>
              <br />
                <li className="collection-item app-py-2 text-center">
                  <p id="no-data" style={{ "text-Align": "center" }}>
                    <span className="app-font-normal">NO WITHDRAWAL YET</span></p>
                </li>
              </>
            )
          }
        </ul>
      </div>
    </>
  )
}

export default WithdrawalList
