import React from 'react'
import LayoutLanding from './layout'
import { Link } from 'react-router-dom'

const NopageLanding = () => {
  return (
    <LayoutLanding>
      {/* <!--banner start--> */}
      <div class="hero-banner creative-banner blog-banner text-center" id="home" style={{ height: "400px" }}>
        <div class="hero-text ">
          <div class="container">
            <div class="row ">
              <div class="col-md-12">
                <h1 class="hero-title text-light mb-3" >404</h1>
                <h2 class="text-light mb-3">Page not found</h2>
                <Link class="text-light mb-2" to='/'>Go home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--banner end--> */}
    </LayoutLanding>
  )
}
export default NopageLanding