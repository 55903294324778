import React from 'react'
import LayoutLanding from './layout'
import { Link } from 'react-router-dom'

const Faq = () => {
  return (
    <LayoutLanding>
      {/* <!--banner start--> */}
      <div class="hero-banner creative-banner blog-banner text-center" id="home" style={{ height: "300px" }}>
        <div class="hero-text ">
          <div class="container">
            <div class="row ">
              <div class="col-md-12">
                <h1 class="hero-title text-light mb-0" >Frequently asked Questions (FAQs)</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--banner end--> */}

      {/* <!--section start--> */}

      <section class="section-gap">
        <div class="container">
          <div className="row">
            <div className="col-md-6 col-gap">
              <div className="accordion wow fadeInUp bg-dark-d text-dull" data-wow-delay=".4s" id="accordion-1">
                <div className="card bg-dark-d text-dull">
                  <div className="card-header bg-dark-d text-dull">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-1-1">
                        What is a trading platform?
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-1-1" className="collapse" data-parent="#accordion-1">
                    <div className="card-body pt-0">
                      <p>It is an online platform where traders track the quotes of different kinds of assets and make trades using the services provided by a broker.</p>
                    </div>
                  </div>
                </div>
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-1-2">
                        The Platform Doesn’t Load
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-1-2" className="collapse" data-parent="#accordion-1">
                    <div className="card-body pt-0">
                      <p>
                        Try opening it in some other browser. We recommend using the latest Google Chrome.
                        The system will not let you log in to the trading platform if your location is blacklisted.
                        Perhaps, there is an unexpected technical problem. Our support consultants will help you solve it.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-1-3">
                        When Will Deposits Be Credited
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-1-3" className="collapse" data-parent="#accordion-1">
                    <div className="card-body pt-0">
                      <p>
                        The funds are usually credited to trading accounts fast,
                        but sometimes it can take from 2 to 5 business days (depending on your payment provider.)
                        If the money has not been credited to your account right after you make a deposit, please wait for 1 hour.
                        If after 1 hour there is still no money, please wait and check again.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-1-4">
                        Do I Need to Install Any Trading Software?
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-1-4" className="collapse" data-parent="#accordion-1">
                    <div className="card-body pt-0">
                      <p>
                        You can trade on our online platform in the web version right after you create an account.
                        There is no need to install new software
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-1-5">
                        Do I Need to Install Any Trading Software?
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-1-5" className="collapse" data-parent="#accordion-1">
                    <div className="card-body pt-0">
                      <p>
                        When system errors occurs, we recommend clearing your cache and cookies.
                        You should also make sure you are using the latest version of the web browser.
                        If you take these actions but the error still occurs, contact our support team.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-gap">
              <div className="accordion wow fadeInUp bg-dark-d text-dull" data-wow-delay=".5s" id="accordion-2">
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-2-1">
                        What are Bonuses
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-2-1" className="collapse" data-parent="#accordion-2">
                    <div className="card-body pt-0">
                      <p>
                        Bonus is the money the company provides to a client for trading.
                        Traders don't lose their own funds when they lose a bonus.
                        On the other hand, in case of success, they pocket the profit gained from using bonus funds.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-2-2">
                        Trading and Quote Sessions
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-2-2" className="collapse" data-parent="#accordion-2">
                    <div className="card-body pt-0">
                      <p>
                        A quote session is a period when the platform receives and transmits quotes.
                        However, one can make trades within a slightly shorter trading session, which is part of a quote session.
                        As a rule, a quote session starts 5-10 minutes earlier and ends 5-10 minutes later than the trading session.
                        This is meant to protect traders from the risk of high volatility at the beginning and the end of the quote session.
                        For example, a quote session for Apple shares starts at 13:30 GMT (US Summer Time) and ends at 20:00.
                        A trading session for Apple shares starts with a five-minute delay, i.e. at 13:35. And it ends at 19:55, which is 5 minutes before the quote session ends.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-2-3">
                        Why Doesn’t a Trade Open Instantly?
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-2-3" className="collapse" data-parent="#accordion-2">
                    <div className="card-body pt-0">
                      <p>It takes a few seconds to get data from the servers of our liquidity providers. As a rule, the process of opening a new trade takes up to 4 seconds.</p>
                    </div>
                  </div>
                </div>
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-2-4">
                        How Can I View the History of My Trades?
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-2-4" className="collapse" data-parent="#accordion-2">
                    <div className="card-body pt-0">
                      <p>
                        All information about your recent trades is available in the “Trades” section.
                        You can access the history of all your trades through the section with the same name as your user account.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-2-5">
                        What Is the Most Active Time of Day for Trading?
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-2-5" className="collapse" data-parent="#accordion-2">
                    <div className="card-body pt-0">
                      <p>
                        Trading activity depends on the working hours of major exchanges and increases at the time of important news releases.
                        The most active trading sessions are European and North American ones.
                        The European session begins at around 6:00 UTC and closes at 15:00 UTC.
                        The North American trading session spans from 13:00 UTC until 22:00 UTC.
                        Please note that some currency pairs and assets are available for trading for a limited period of time.
                        The trading hours for each asset are specified in the “Trading Conditions” tab of the “Asset” menu.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br /><br />

          <div className="row">
            <div className="col-md-6 col-gap">
              <div className="accordion wow fadeInUp bg-dark-d text-dull" data-wow-delay=".4s" id="accordion-3">
                <div className="card bg-dark-d text-dull">
                  <div className="card-header bg-dark-d text-dull">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-3-1">
                        How does Bitcoin mining work
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-3-1" className="collapse" data-parent="#accordion-3">
                    <div className="card-body pt-0">
                      <p>
                        It’s quick and very easy! As soon as we receive your payment your contract will be added to your profile, and you can immediately start mining.
                        Depending on the blockchain algorithm you select and the associated mining service agreement you enter into, you can either mine native cryptocurrencies directly or allocate your hashpower to other cryptocurrencies (marked with AUTO), and even choose a specific allocation for them.
                        For example: 60% LTC, 20% BTC and 20% DOGE.
                        The first mining output is released after 48 hours, and then a daily mining output will follow.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-3-2">
                        Are you mining for yourself?
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-3-2" className="collapse" data-parent="#accordion-3">
                    <div className="card-body pt-0">
                      <p>
                        Besides the fact that we ourselves mine with the very same hardware that we offer to our clients, our capital is limited. We believe that Bitcoin and Altcoin mining is one of the best ways to receive Cryptocurrencies, however, we do not want to “put all our eggs in one basket”.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-3-3">
                        Where is your mining farm located?
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-3-3" className="collapse" data-parent="#accordion-3">
                    <div className="card-body pt-0">
                      <p>
                        For security reasons, we do not disclose the exact location of our mining farms. As of April 2015, we are operating several mining farms that are located in Europe, America and Asia. Electricity cost and availability of cooling are important, but not the only criteria. See our Datacenters page for more information.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-3-4">
                        What coins I mine with each algorithm?
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-3-4" className="collapse" data-parent="#accordion-3">
                    <div className="card-body pt-0">
                      <p>
                        You are able to mine Bitcoin and various altcoins directly via our mining allocation page*. The availability of cryptocurrencies you can mine depends on the contract you have chosen.

                        You must allocate your hashpower in order to determine the cryptocurrency received for your mining output. If no allocation has been made, the mining output will default to the following for the given blockchain algorithm:

                        SHA-256 contract - Bitcoin
                        Ethash contract - Ethereum
                        (*) “Quantumcrypta Advanced Allocation” (special feature): It allows you to get mining outputs in many different coins even if they are not mined directly by a certain algorithm. For example, you can get mining outputs in BTC while mining with an X11 algorithm! That is possible by mining the DASH coin directly, which is then automatically swapped to BTC by our algorithmic framework. The Allocation function is designed for customers to receive delivery of their mining results in their preferred cryptocurrency. We call it “mining BTC the smart way”. The same technique is also used to get mining results in LTC with a SHA-256 contract, etc.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-3-5">
                        How do your ether contracts work?
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-3-5" className="collapse" data-parent="#accordion-3">
                    <div className="card-body pt-0">
                      <p>
                        When you purchase an Ether mining contract, you buy a share of a GPU (“graphics processing unit“) to mine Ethereum. The hashing algorithm used to mine Ether is called Ethash (or Dagger-Hashimoto).

                        Ethash requires the so called DAG file in order to perform the calculations. This file grows over time, which means that more operations for each hashing operation need to be performed. This means that the effective hashrate decreases a little each time the DAG file grows.

                        This has to be considered when comparing the theoretical output of your hashpower. Online calculators do not take this into account.

                        Also, please keep in mind that some of our products have a maintenance fee attached.


                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-gap">
              <div className="accordion wow fadeInUp bg-dark-d text-dull" data-wow-delay=".5s" id="accordion-4">
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-4-1">
                        What are the expected returns?
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-4-1" className="collapse" data-parent="#accordion-4">
                    <div className="card-body pt-0">
                      <p>
                        Bitcoin and altcoin mining depends highly on the prices and network difficulties of the Cryptocurrencies that you are mining, which cannot be predicted. It would not “look right” if we would provide data on the mining performance and sell contracts at the same time. Therefore, we recommend all our clients to do their own research and do their own performance calculation.

                        For example, you might want to have a look at coinwarz.com. Remember that some of our products have a daily maintenance fee which must be deducted from the daily mining rewards.

                        Please note that the website is an independent source of reference and not related to Quantumcrypta in any manner.


                      </p>
                    </div>
                  </div>
                </div>
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-4-2">
                        Which pools are you using for mining?
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-4-2" className="collapse" data-parent="#accordion-4">
                    <div className="card-body pt-0">
                      <p>
                        We do not publish a list of pools we are using.
                        Our main criteria for a good pool are: reliability, fee structure and reject rate. Going forward we will solo-mine a few coins (and pass the fee savings to our users!).
                        Our internal policy is: “be a good crypto citizen”.
                        This means, that we will at least use two different pools (in some cases we use up to four) for each coin.
                        This is to preserve the decentralized nature of the crypto networks! If we become aware that a pool is getting close to 50% share, we will switch away from it and use a backup instead.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-4-3">
                        Isn't buying hardware cheaper?
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-4-3" className="collapse" data-parent="#accordion-4">
                    <div className="card-body pt-0">
                      <p>
                        On the first glance one might think that buying miner X is slightly cheaper than hosted mining. However, there are a number of “hidden” costs related to purchasing and maintaining a miner that change the economics significantly:

                        Shipping costs: few merchants will cover this, but in most cases they will charge you extra.

                        Customs dues / VAT: if it is an international delivery you will likely have to pay customs dues and VAT; generally this has a huge impact on the total cost of your mining equipment.

                        Delivery time: when you pay for your device you have to wait until the miner is packaged and shipped; if it is held up in the customs this can take weeks. And during that time you cannot mine at all (“opportunity costs”). You have to wait and hope. And if the merchant has a stock shortage; it is you who has to wait. With Quantumcrypta you pay and get your hashpower immediately online. No delays!

                        Costs for additional equipment: when buying miners from larger retailers you often have to buy additional equipment like an extra PSU that matches the electricity specs for your country, control units and specific shelves to store them efficiently. This adds up significantly to the overall price for hashpower.

                        Setting the system up: when the device finally arrived you have to assemble and configure it. Beginners often underestimate the amount of work and technical knowledge that is needed to bring the device up and optimize its settings. People that used the first ASICs spend days and weeks on it. In monetary terms this costs you twice: (i) your time and (ii) lost mining opportunity.

                        Maintenance: hardware failures, outages and crashes could lead to downtimes between seconds and weeks. If your unit breaks you have to return it and start from scratch (by the way: check the guarantee time of your miner; it is often less than a year). With Quantumcrypta you have a guaranteed uptime of 100%: in case one of your units fails another unit will be switched over and start mining for you the very same second.

                        Electricity: having the machines at home not only brings heat and noise, but also an increase of your electricity bill; an important factor that many underestimate. Please do not get us wrong: we do not want to discourage anyone from mining at home! We are often asked about prices and realized that many users do not know about all the hidden costs. If you compare the true costs, you will notice that our prices are very competitive. We want to provide you the best possible mining experience, so that you can focus on mining your favorite coins (and we take care of the rest).
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-4-4">
                        What is the maintenance fee?
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-4-4" className="collapse" data-parent="#accordion-4">
                    <div className="card-body pt-0">
                      <p>
                        Some of our products have a maintenance fee attached. The maintenance fee covers all costs related to mining including, inter alia:

                        electricity cost
                        cooling
                        maintenance work
                        hosting services

                        The fee is fixed in USD but deducted from the daily mining rewards in the natively mined coin on a daily basis.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card bg-dark-d text-dull">
                  <div className="card-header">
                    <h5>
                      <Link className="collapsed bg-dark-d" data-toggle="collapse" data-target="#collapse-4-5">
                        How can I mine different coins at the same time?
                      </Link>
                    </h5>
                  </div>
                  <div id="collapse-4-5" className="collapse" data-parent="#accordion-4">
                    <div className="card-body pt-0">
                      <p>
                        Quantumcrypta allows its clients to mine different types of coins at the same time. You decide which coins you prefer and you can allocate hashpower accordingly.

                        Each mining algorithm is set up with a default delivery cryptocurrency. The Quantumcrypta Advanced Auto-Allocation (in short “AUTO”) will apply to all cryptocurrencies which cannot be mined directly. In the user interface go to “Mining Allocation” and choose the hashpower allocation that is best for you. When you are done press “save allocation”.

                        Remember that the cryptocurrencies marked with (AUTO) are not directly mined, as mentioned in the “What coins can I mine?” section of this Customer Service page.

                        In order to get the mining output, please ensure to add your wallet address(es) to your Quantumcrypta profile. Find out more details about how to add your wallet(s) in the next section.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br /><br />
        </div>
      </section>
    </LayoutLanding>
  )
}

export default Faq
