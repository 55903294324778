import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { useAuth } from '../authentication/userAuth';
import { toast } from 'react-toastify';

const UserReferals = () => { 
  const { user } = useAuth();
  const [copyText, setCopyText] = useState(`https://quantumcrypta.net/register/?uid=${user.owner_id}`);
  const [referals, setReferals] = useState([]);
  const month = { '01': 'Jan', '02': 'Feb', '03': 'Mar', '04': 'Apr', '05': 'May', '06': 'June', '07': 'July', '08': 'Aug', '09': 'Sep', '10': 'Oct', '11': 'Nov', '12': 'Dec' };

  const handleCopyText = (e) => {
    setCopyText(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`https://quantumcrypta.net/api/user/referals/`, {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({
          id:user.owner_id
        }) 
      })
      const response = await data.json();
      if (response.status === 200) {
        setReferals(response.referals)
      }
    }
    fetchData();
  }, [user.owner_id])

  return (
    <>
      <div className="roww container">
        <div className="coll l4 s12 center">
          <div className="card-panel app-py-2 app-px-2">
            <h1>${user.referal_balance}</h1>
            <span>Referral Balance</span><br /><br />
            <Link className="btnn" to="/user/withdrawals/crypto">WITHDRAW</Link>
          </div><br />
          <div className="card-panel app-py-2 app-px-2">
            <div className="input-field container" style={{ "marginBottom": "0px" }}>
              <CopyToClipboard text={copyText}
                onCopy={() => toast("Referal link copied successfully")}>
                <i className="material-icons prefix">content_copy</i>
              </CopyToClipboard>
              <input type="text" readonly="" onChange={handleCopyText} defaultValue={copyText} style={{ "textAlign": "center" }} />
            </div>
            <span>Referral Link</span>
          </div><br />
        </div>
        <div className="coll l8 s12">
          <div className="container">
            <ul className="collection">
              {
                (referals.length > 0) ? (
                  referals.map((item, index) => (
                    <>
                      <li className="collection-item app-py-1 " key={index}>
                        <div className="roww app-list-link">
                          <div>
                            <div className="coll l1 s2" style={{ "padding": "0px" }}>
                              <center>
                                <p className="app-trading-month">{month[item.createdAt.split(' ').split('-')[1]]}</p>
                                <span className="app-trading-day">
                                  {item.createdAt.split(' ').split('-')[1]}
                                </span>
                              </center>
                            </div>
                            <div className="coll l9 s7" style={{ "fontSize": "14px" }} >
                              {item.user.fullname}
                            </div>
                          </div>
                          <div className="coll l2 s3" style={{ "textAlign": "right", "fontSize": "14px" }}>{item.status}</div>
                        </div>
                      </li >
                    </>
                  ))
                ) : (
                  <>
                    <li className="collection-item app-py-2">
                      <p id="no-data" style={{ "textAlign": "center" }}><span className="app-font-normal">No Referrals Yet</span></p>
                    </li>
                  </>
                )
              }
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
export default UserReferals