import React, { useState } from 'react'
import { InfinitySpin } from 'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from '../../authentication/userAuth';
import { useNavigate } from 'react-router-dom';

const PasswordUpdate = () => {
    const { user } = useAuth();

    const [passwordOld, setOldPassword] = useState('');
    const [passwordNew, setNewPassword] = useState('');
    const [passwordConfirm, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsloading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true);

        if (passwordNew === passwordConfirm) {
            try {
                let data = {
                    NewPassword: passwordNew, 
                    oldPassword: passwordOld,
                    id: user.owner_id
                }

                const resp = await fetch('https://quantumcrypta.net/api/user/account/update/password', {
                    method: "POST",
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify(data)
                });
                const response = await resp.json();

                if (response.status === 200) {
                    toast(response.message);
                    setIsloading(false);
                    setTimeout(() => {
                        navigate('/user/account');
                    }, 2000);
                } else {
                    setError(response.message)
                    toast(response.message)
                }

            } catch (err) {
                setIsloading(false)
                setError(err.message)
            }
        } else {
            setIsloading(false)
            toast("Passsword does not match")
            setError("Passsword does not match")
        }

    }
    return (
        <>
            <div className="roww"><br /><br />
                <div className="coll l6 offset-l3 s12">
                    <ToastContainer />
                    <div className="cardd-panel">
                        <center>
                            <h3 className="btnn-color">Password update</h3><br />
                            <p>Update password details here</p><br />
                            <form autoComplete="off" onSubmit={handleSubmit}>
                                <div className="roww">
                                    <div className="input-field undefined">
                                        <input type="password" id="password" name="password" autoComplete="new-password" required="" value={passwordOld}
                                            onChange={(e) => setOldPassword(e.target.value)} aria-autocomplete="list" />
                                        <label className="active" htmlFor="password">password</label>
                                    </div>
                                    <div className="input-field undefined">
                                        <input type="password" id="new_password" name="new_password" autoComplete="new-password" required="" value={passwordNew}
                                            onChange={(e) => setNewPassword(e.target.value)} />
                                        <label className="active" htmlFor="new_password">new password</label>
                                    </div>
                                    <div className="input-field undefined">
                                        <input type="password" id="confirm_new_password" name="confirm_new_password" autoComplete="new-password" required="" value={passwordConfirm}
                                            onChange={(e) => setConfirmPassword(e.target.value)} />
                                        <label className="active" htmlFor="confirm_new_password">confirm new password</label>
                                    </div>
                                </div>
                                {
                                    isLoading ? (
                                        <InfinitySpin
                                            visible={true}
                                            width="200"
                                            color="#4fa94d"
                                            ariaLabel="infinity-spin-loading"
                                        />
                                    ) : (
                                        <>

                                            <div><button type="submit" className="btnn btnn-full">Update</button></div><br />
                                            {
                                                error && (
                                                    <div className="red black-text lighten-4 card-panel text-center" style={{ "padding": "30px" }}><span>{error}</span><br /></div>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </form>
                        </center>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PasswordUpdate
