import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const MiningPlans = () => {
  const [plans, setPlans] = useState([])
  useEffect(() => {
    async function fetchData() {
      const response = await fetch(`https://quantumcrypta.net/api/user/plans`, { method: "POST" });
      const resp = await response.json();
      setPlans(resp.plans.filter(plan => plan.category.toLowerCase() === 'mining'));
    }
    fetchData(); 
  }, [])
  return (
    <>
      <div className="container">
        <h3 className="center">Mining Contracts</h3><br /><br />
        <section className="roww">
          {
            (plans.length > 0 )? (
              plans.map((plan, index) => (
                <div className="coll l3 s12 wow fadeInUp slow" key={index}>
                <div className="cardd hoverable app-py-1 app-px-2" style={{ "borderRadius": "10px" }}>
                  <div className='roww'>
                    <div className='coll l12 s12'>
                      <div className='coll l9 s9'>
                        {plan.subcategory} {plan.category}<br />
                        <span style={{ "fontSize": "30px" }}>{plan.title}</span>
                      </div>
                      {
                        plans.image && (
                          <div className='coll l3 s3' style={{ textAlign: "right" }}>
                            <img src={`${plan.image}`} alt='' style={{ "maxHeight": "20px", "borderRadius": "5px" }} />
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <hr />
                  <center>
                    <h4 className="alt-color" style={{ "fontSize": "35px", "fontWeight": "bold" }}>${plan.price}</h4><br /><br />
                    <Link className="btnn " to={`/user/deposits/fund/step3/purchase/${plan.price}/${plan.subcategory} ${plan.category} ${plan.title}`}>PURCHASE PLAN</Link>
                  </center>
                </div>
              </div>
              ))
            ) : (
              <></>
            )
          }

        </section >
      </div >
    </>
  )
}

export default MiningPlans
