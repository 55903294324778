import React, { useContext } from 'react'
import LayoutLanding from './layout'
import { AuthContext } from '../context';

const ContactUs = () => {
    const { sitesettings } = useContext(AuthContext);
    return (
        <LayoutLanding>
            {/* <!--banner start--> */}
            <div class="hero-banner creative-banner blog-banner text-center" id="home" style={{ height: "300px" }}>
                <div class="hero-text ">
                    <div class="container">
                        <div class="row ">
                            <div class="col-md-12">
                                <h1 class="hero-title text-light mb-0" >Contact us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--banner end--> */}
            <main>
                <section className="pt-5 pb-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 gx-0 gx-md-5 gx-lg-5 mt-4">
                                    <div className="col border-end-0 border-end-md border-end-lg">
                                        <h4 className="fw-bold border-start border-5 ps-1">How-To's & Tutorials</h4>
                                        <div className="d-flex align-items-start mt-2">
                                            <div>
                                                <i className="fas fa-question fa-3x ms-1 ms-md-1 ms-lg-3 text-dark text-opacity-50"></i>
                                            </div>
                                        </div>
                                        <hr className="d-md-none d-lg-none my-3 my-md-3" />
                                    </div>
                                    <div className="col border-end-0 border-end-md border-end-lg d-md-none d-lg-block">
                                        <h4 className="fw-bold border-start border-5 ps-1">Security and Privacy</h4>
                                        <div className="d-flex align-items-start mt-1">
                                            <div>
                                                <i className="fas fa-unlock-alt fa-3x ms-1 ms-md-1 ms-lg-3 text-dark text-opacity-50"></i>
                                            </div>
                                        </div>
                                        <hr className="d-lg-none my-3 my-md-3" />
                                    </div>
                                    <div className="col">
                                        <h4 className="fw-bold border-start border-5 ps-1">Open a Support ticket</h4>
                                        <div className="d-flex align-items-start mt-2">
                                            <div>
                                                <i className="fas fa-life-ring fa-3x ms-1 ms-md-1 ms-lg-3 text-dark text-opacity-50"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInUp" data-wow-delay=".2s">
                                <hr className="my-2" />
                                <h1 className="pt-2 text-center">Have a <span className="text-highlight">question?</span></h1>
                                <p className="lead text-muted text-center">Let's get in touch!</p>
                                <br />
                                <div className="row">
                                    <div className="col-sm-4 text-center">
                                        <h5 className="fw-bold mb-1">Address</h5>
                                        <p className="text-muted">{sitesettings.address}</p>
                                    </div>
                                    <div className="col-sm-4 text-center">
                                        <h5 className="fw-bold mb-1">Email</h5>
                                        <p className="text-muted">{sitesettings.email}<br />for public inquiries</p>
                                    </div>
                                    <div className="col-sm-4 text-center">
                                        <h5 className="fw-bold mb-1">Call</h5>
                                        <p className="text-muted">{sitesettings.phone}<br />Mon - Fri, 9am - 5pm</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6'>
                                <div id="contact" className="card text-dark py-md-3 px-md-4 wow fadeInUp" data-wow-delay=".3s">
                                    <div className="card-body">
                                        <div className="row justify-content-md-center text-center">
                                            <div className="col-md-12 col-12">
                                                <form id="contactForm" className="contact-form text-left" data-toggle="validator">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group ">
                                                                <input type="text" id="name" className="form-control" placeholder="Name" required data-error="Please enter your name" />
                                                                <div className="help-block with-errors"></div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group ">
                                                                <input type="email" className="email form-control" id="email" placeholder="Email" required data-error="Please enter your email" />
                                                                <div className="help-block with-errors"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="controls">
                                                            <input type="text" id="msg_subject" className="form-control" placeholder="Subject" required data-error="Please enter your message subject" />
                                                            <div className="help-block with-errors"></div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="controls">
                                                            <textarea id="message" rows="10" placeholder="Message" className="form-control" required data-error="Write your message"></textarea>
                                                            <div className="help-block with-errors"></div>
                                                        </div>
                                                    </div>

                                                    <div className="text-center mt-md-5">
                                                        <button type="submit" id="submit" className="btn btn-purple btn-pill text-uppercase">Submit</button>
                                                        <div id="msgSubmit" className="h5 text-center hidden"></div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-2" />
                        </div>
                    </div>
                </section>
            </main>
        </LayoutLanding>
    )
}

export default ContactUs
