import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';

const SingleContract = () => {
    const navigate = useNavigate();
    const [contract, setContract] = useState({})
    const {id} = useParams();
    useEffect(() => {
      const fetchData = async () => {
        const data = await fetch(`https://quantumcrypta.net/api/user/trading/contracts/single`, {
          method: "POST",
          headers: {
            "content-Type": "application/json"
          },
          body: JSON.stringify({id}) 
        })
        const response = await data.json();
        if(response.status === 200) {
            setContract(response.contract)
        }else{
          navigate('/user/contracts/');
        }
      } 
      fetchData();
    }, [navigate, id])
    return (
      <>
        <section className="container">
          <div className="cardd-panel">
            <div className="roww">
              <div className="coll l3 s12 center">
                <p><Link to={`/user/deposits/fund/step3/buy_contract/${contract.amount}/trading_deposit`}>Make Payment</Link></p>
                <p><Link to={`/user/deposits/upload-proof/${contract.id}`}>Upload Payment Proof</Link></p><br/>
              </div>
              <div className="coll l9 s12">
                <table className="striped">
                  <tbody>
                    <tr className="roww">
                      <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>Date</td>
                      <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>{contract.createdAt}</td>
                    </tr>
                    <tr className="roww">
                      <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>Status</td>
                      <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>{contract.status}</td>
                    </tr>
                    <tr className="roww">
                      <td className="coll l4 s7 table-title" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>Amount</td>
                      <td className="coll l8 s5 table-content" style={{ "paddingTop": "1rem", "paddingBottom": "1rem" }}>${contract.amount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </>
    )
}

export default SingleContract
