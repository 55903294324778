import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../authentication/userAuth';

const VerificationHome = () => { 
    const { user } = useAuth();
    return ( 
        <> 
            <center>
                <img src={`https://quantumcrypta.net/uploads/${user.profilephoto}`} className="circle" style={{width:"150px", height:"150px"}} alt="" />
                <h2>{user.fullname}</h2>
                <p>VERIFICATIONS</p>
            </center>
            <div className="roww">
                <div className="coll l6 s12 offset-l3">
                    <ul className="collection">
                        <Link className="collection-item bg" to="/verify/email/">
                            <li>
                                <div style={{ "padding": "1rem 1rem 0.8rem", "background": "rgb(181, 72, 198)", "borderRadius": "10px", "display": "inline-block", "marginRight": "1rem" }}>
                                    <span className="material-icons notranslate" style={{ "fontSize": "30px", "margin": "0px", "padding": "0px", "color": "white" }}>mail</span>
                                </div>
                                Email Verification
                                <span style={{ "color": "black", "fontSize": "12px" }}>{
                                    user.emailVerificationStatus ? 'Done' : 'Skipped'
                                }</span>
                            </li>
                        </Link>
                        <Link className="collection-item bg" to="/user/account/verification/identity">
                            <li>
                                <div style={{ "padding": "1rem 1rem 0.8rem", "background": "rgb(225, 85, 93)", "borderRadius": "10px", "display": "inline-block", "marginRight": "1rem" }}>
                                    <span className="material-icons notranslate" style={{ "fontSize": "30px", "margin": "0px", "padding": "0px", "color": "white" }}>person</span>
                                </div>
                                Identity Verification
                                <span>{
                                    (user.idVerifyStatus === "1") ? (
                                        <span style={{ "color": "black", "fontSize": "12px" }}>
                                            Done
                                        </span>
                                    ) : (
                                        (user.idVerifyStatus === "3") ? (
                                            <span style={{ "color": "black", "fontSize": "12px" }}>
                                                Under review
                                            </span>
                                        ) : (
                                            <span style={{ "color": "black", "fontSize": "12px" }}>
                                                Not verified
                                            </span>
                                        )
                                    )
                                }</span>
                            </li>
                        </Link>
                        <Link className="collection-item bg" to="/user/account/verification/address">
                            <li>
                                <div style={{ "padding": "1rem 1rem 0.8rem", "background": "rgb(82, 82, 152)", "borderRadius": "10px", "display": "inline-block", "marginRight": "1rem" }}>
                                    <span className="material-icons notranslate" style={{ "fontSize": "30px", "margin": "0px", "padding": "0px", "color": "white" }}>home</span>
                                </div>
                                Address Verification
                                <span style={{ "color": "black", "fontSize": "12px" }}>{
                                    (user.addressVerificationStatus === "1") ? (
                                        <>
                                            Done
                                        </>
                                    ) : (
                                        (user.addressVerificationStatus === "0") ? 'Skipped' : 'Under review'
                                    )
                                }</span>
                            </li>
                        </Link>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default VerificationHome
