import React from 'react'
import LayoutLanding from './layout'
import { Link } from 'react-router-dom'

const CryptoMining = () => {
    return (
        <LayoutLanding>
            {/* <!--banner start--> */}
            <div class="hero-banner creative-banner blog-banner text-center" id="home" style={{height:"300px"}}>
                <div class="hero-text ">
                    <div class="container">
                        <div class="row ">
                            <div class="col-md-12">
                                <h1 class="hero-title text-light mb-0" >Crypto Mining</h1>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <!--banner end--> */}

            {/* <!--section start--> */}

            <section class="section-gap">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 pr-lg-5 pb-5">
                            <article class="blog-post">
                                <div class="d-flex mb-3 post-meta">
                                    <span class="post-date pr-3">Author: <Link to="#">Admin</Link></span>
                                    <span class="post-date border border-top-0 border-bottom-0 px-3">June 10, 2018</span>
                                    <Link to="#">
                                        <span class="d-flex align-items-center px-3"><i class="fa fa-comments-o text-3 mr-1" aria-label="4 users comment this post"></i> 4</span>
                                    </Link>
                                </div>
                                <p>
                                    Cryptocurrency networks need computational power to run securely. The world’s most powerful blockchains are supported by millions of computers around the world. Unlike paper money, Bitcoin and other cryptocurrencies are produced mathematically and held digitally. The people who voluntarily offer their computing power to secure these cryptocurrency networks are called miners.
                                </p>

                                <p>
                                    Cryptocurrencies don’t have a central government or other so-called ‘middlemen’ that decide about the future of the system. – They are in fact digital, borderless democracies in which miners vote with their computing power to reach order and consensus.
                                </p>


                                <h3>HOW IS BITCOIN KEPT SECURE</h3><br />
                                <p>
                                    The only way to not create money out of thin air or corrupt it, is to burn real energy. This is called Proof-Of-Work. Today, bitcoin is the most secure computer network on the planet and it’s practically impossible to break it! The reason for that is hash power, the umbrella term used for the computing power that miners provide to the bitcoin network and similar cryptocurrency networks. Due to the rapid growth of the ecosystem, mining operations today are mostly running with specialized high-performance computers that function most efficiently set up in large data centers.
                                </p>


                                <h3>THE BIG VISION OF CRYPTO CURRENCY</h3><br />
                                <p>

                                    To keep the integrity (and values!) of all cryptocurrency ecosystems intact, miners keep the networks safe and its authority decentralized by keeping each other constantly in check. This allows both a healthy growth and a fair distribution of currency units to all crypto-citizens!
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </LayoutLanding>
    )
}
export default CryptoMining