import React, { useEffect } from 'react'

const GoogleTranslate = () => {
    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          { 
            pageLanguage: "en",
            layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            includedLanguages: 'en,es,pt,nl,in,ar,se,fr,de,id,vn,vi,fa,ru,pl,ml,hr,cs,nl,it',
            autoDisplay: true
          },
          "google_translate_element"
        );
      };
    
      useEffect(() => {
        var addScript = document.createElement("script");
        addScript.setAttribute(
          "src",
          "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
      }, []);
    
  return (
    <div id="google_translate_element" style={{width:"100px", overflow:"auto"}}></div>
  )
}
export default GoogleTranslate